import { useNavigate } from '@remix-run/react';
import { useEffectOnce } from 'react-use';

import { useUserAnalytics } from '../analytics/user';
import { usePostLogin } from '../components/Access';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GlobalLoading } from '../components/GlobalLoading';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useQueryParam } from '../hooks/useQueryParam';
import { apiService } from '../services/api-service';
import { err2s } from '../utils/common';

function Container() {
  const navigate = useNavigate();
  const postLogin = usePostLogin();

  const error = useQueryParam('error');
  const code = useQueryParam('code');
  const state = useQueryParam('state');

  const scenario = useQueryParam('scenario');
  const from = useQueryParam('from') || '';
  const search = useQueryParam('search') || '';
  const redirectTo = useQueryParam('redirect-to') || from || '/home';

  useEffectOnce(() => {
    const run = async () => {
      if (error) {
        navigate(
          {
            pathname: from,
            search,
          },
          {
            state: { success: false, error, scenario },
          }
        );
        return;
      }

      if (!code || !state) return;

      try {
        switch (scenario) {
          case 'register':
            const registerResp = await apiService.auth.slackRegister({
              code,
              state,
              queries: Object.fromEntries(
                new URLSearchParams(search).entries()
              ),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
            postLogin(registerResp.data);
            navigate(
              {
                pathname: '/onboarding/headcount',
                search: search,
              },
              {
                replace: true,
              }
            );
            return;
          default:
            const resp = await apiService.slack.installCallback({
              code,
              state,
            });
            navigate(redirectTo, {
              state: {
                success: true,
                connection: resp.data.connection,
              },
              replace: true,
            });
            return;
        }
      } catch (error) {
        const apiErr = apiService.utils.CastAPIError(error);
        if (apiErr?.msg === 'email_already_registered') {
          navigate(
            {
              pathname: '/login',
            },
            {
              state: {
                error:
                  'Oops! That Slack account is already associated with a Luna Park account. You can login below!',
              },
            }
          );
        } else if (apiErr?.msg === 'ex_conn_already_associated') {
          navigate(
            {
              pathname: '/login',
            },
            {
              state: {
                error:
                  'Oops! That Slack workspace is already connected to a Luna Park organization. Ask your workspace admin to invite you to the organization!',
              },
            }
          );
        } else {
          navigate(redirectTo, {
            state: { success: false, error: err2s(error) },
            replace: true,
          });
        }
      }
    };

    run();
  });

  return <GlobalLoading />;
}

export function Component() {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Container />

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
